import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';

import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component


function App() {
  return (
<>
<Router>
    <Navbar />
    <ScrollToTop /> {/* Include ScrollToTop here */}
    <Routes>
    
    <Route path='/' element= { <Home/>} />    
    <Route path="/services" element={<Services />} />
    </Routes>
</Router>
  </>
  );
}

export default App;
