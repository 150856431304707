import React from 'react';
import './App.css';
import HeroSection from './HeroSection.js';
import Cards from './Cards.js';
import Footer from './Footer.js';
import Contact from './contact.js';
function Home () {
    return (
        <>
        <HeroSection />
        <Cards />
        <Contact/>
        <Footer />
        
        </>
    );
}

export default Home;