import React from 'react'
import { Link } from 'react-router-dom';


function CardItem(props)
{

    const go_to_service = () => {
       // var get_current_location = window.location.href;

        const currentUrl = window.location.href;

// Create a URL object
const url = new URL(currentUrl);

// Get the URL without the parameters
//const urlWithoutParams = url.origin + url.pathname;

        window.location.href = url.origin + props.path; // Replace with the desired phone number
      };

    return (
        <>
            <div class='card_container'>
            <li className="cards_item">
                <Link className="cards__item__link" to={props.path}>
                    <figure className="cards__item__pic-wrap" data-category={props.label}>
                        <img src={props.src} 
                        alt="Travel Image" 
                        className="cards__item__img"/>
                        
                    </figure>
                    <div className='cards__item__info'>
                        <h5 className='cards__item__text' >
                            {props.text}
                        </h5>
                        <p className="cards_item_details">{props.text_details}</p>
                        <button className="read_more">Read more</button>
                    </div>
                    
                </Link>

                
            </li>
            


            <div className='responsive_item' >
                
                    <img src={props.src} alt={props.text} className='responsive_image'/>
                    <div className='back_color'></div>
                    <h5 className='responsive_title' >
                            {props.text}
                        </h5>


                        <p className="responsive_details">{props.text_details}</p>
                        <Link to={props.path}>    <button /*onClick={() => { go_to_service();}}*/ className="responsive_read"> Read more</button> </Link>
                    
                </div>
                
            </div>

            
        </>
    )
}

export default CardItem;