import React from 'react';
import './App.css';
import ServicePage from './ServicePage.js';
import Footer from './Footer.js';
import Contact from './contact.js';
function Services () {
    return (
        <>
        <ServicePage/>
        <Contact/>
        <Footer />
        
        </>
    );
}

export default Services;