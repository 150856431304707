import React from 'react'
import { Button } from './Button'
import './Footer.css'
import { Link } from 'react-router-dom';
function Footer() {
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      const handleEmailClick = () => {
        window.location.href = 'mailto:edilonerestoration@yahoo.com?subject=Hello&body=This%20is%20the%20body%20of%20the%20email.';
      };

      const handleCallClick = () => {
        window.location.href = 'tel:4372990354'; // Replace with the desired phone number
      };

  return (
    <div className='footer-container'>
      
    
        <div className="footer-links">
            
            <div className="footer-link-wrapper">
                <div className="first_footer">
                <Link to="/" className="footer_logo">
            EdilRestoration<i className="fab fa-typo3"></i>
            </Link>
            
            <p>Edil Restoration provides comprehensive services in construction, renovation, and project management, delivering practical solutions tailored to your needs</p>
               
               <div className="socials-icons">
                <img src="facebook_transparent.png"/>
                <img className="insta" src="insta.png"/>

</div>
                </div>

                <div className="footer-link-items">
                    
                    <h2>SERVICES</h2>
                    <Link onClick={() => scrollToTop()} to='/services?type=epoxy-flooring'>&ndash; Epoxy Flooring</Link>
                    <Link onClick={() => scrollToTop()} to='/services?type=sidewalk-flooring'> &ndash; Sidewalk Flooring</Link>
                    <Link onClick={() => scrollToTop()} to='/services?type=concrete-repairs'>&ndash; Concrete repairs</Link>
                    <Link onClick={() => scrollToTop()} to='/services?type=brick-repairs'>&ndash; Brick repairs</Link>
                    <Link onClick={() => scrollToTop()} to='/services?type=paving-interlock'>&ndash; Paving interlock </Link>
                    <Link onClick={() => scrollToTop()} to='/services?type=stucco-finishes-repairs'>&ndash; Stucco finishes & repairs</Link>

                </div>

                <div className="footer-link-items">
                    
                    <h2>GET IN TOUCH</h2>
                    <Link onClick={() => { handleCallClick();}}><div className="contact_section"><img src="phone-icon.png"/><p>(437) 299-0354</p> </div> </Link>
                    <Link to='/' onClick={handleEmailClick}><div className="contact_section"><img src="email-icon.png"/><p>edilonerestoration@yahoo.com</p></div></Link>

                </div>


            </div>
        </div>

    </div>
  )
}

export default Footer;
