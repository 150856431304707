import React, {useState, useEffect} from 'react';
import './App.css';
import { Button } from './Button';
import { Button1} from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {

    const handleCallClick = () => {
        window.location.href = 'tel:4372990354'; // Replace with the desired phone number
      };

    return (
        <>


        <div className='hero-container' id='hero-cont'>
            
            
        
        <div class="video-container">
            <video src='../renovation_promo.mp4' 
            autoPlay loop muted playsinline />
        </div>
        <img className='responsive_hero' src='responsive_logo.jpg'/>
            
            <div className="back">
            <h1>Renovation Excellence <br></br> Where Every Detail Matters</h1>
            <p className="hero_services">We provide a range of high-quality services including  Residential Renovations, Structural Repairs, Additions and Extensions, Remodeling Services and much more</p>
            </div>
            <div className="hero-btns">
            <Button1 className='btns' 
                buttonStyle='btn--primary' 
                 buttonSize='btn--large'
                 >
               
         
                    Request A quote
                
                </Button1>
                <Link onClick={() => { handleCallClick();}} >
                <Button onclick={handleCallClick} className='btns' 
                buttonStyle='btn--primary' 
                buttonSize='btn--large'>
                    
                    Call us now
                    
                </Button>
                </Link>
            </div>
        </div>
        </>
    )
}

export default HeroSection;