import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {Button} from './Button';
import './Navbar.css';

function Navbar() {


    const handleCallClick = () => {
        window.location.href = 'tel:4372990354'; // Replace with the desired phone number
      };

/*
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth',  block: 'start' });
        }
        };
*/


        const scrollToSection = (sectionId) => {
            const section = document.getElementById(sectionId);
            
            // Check if the section exists on the current page
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                // If section doesn't exist, navigate to the home page
                window.location.href = '/#services';
                
                // Use a timeout to ensure navigation occurs before trying to scroll
                setTimeout(() => {
                    const homeSection = document.getElementById(sectionId);
                    if (homeSection) {
                        homeSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }, 100); // Adjust the timeout duration if necessary
            }
        };





    const [click, setClick] = useState(false);

const [button, setButton] = useState(true);

const handleClick = () => setClick(!click);

const closeMobileMenu = () => setClick(false);


const showButton = () => {
    if(window.innerWidth  <= 960) {
        setButton(false);

    } else {
        setButton(true);
    }
};

useEffect(() => {
    showButton();
}, []);

window.addEventListener('resize', showButton);

return (
    <>
    <nav className="navbar" id='navigim'>
        <div className="navbar-container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>EdilRest Toronto<i className="fab fa-typo3"></i>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item first">
                    <Link to='/' className='nav-links' onClick={() => { scrollToSection('hero-cont'); closeMobileMenu(); }}>
                    Home
                    </Link>
                </li>

            {/*
                <li className="nav-item">
                    <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                    About us
                    </Link>
                </li>
            */}
                
                <li className="nav-item">
                  


                    <Link 
  className='nav-links' 
  onClick={() => {
    scrollToSection('section2');
    closeMobileMenu();
  }}
>
    Our Services
  {/* Link content here */}
</Link>
                </li>

                <li className="nav-item">
                   
                    <Link 
  className='nav-links' 
  onClick={() => {
    scrollToSection('section1');
    closeMobileMenu();
  }}
>Contact us
  {/* Link content here */}
</Link>
                </li>

                <li className="nav-item">
                    <Link to='/' className='nav-links-mobile' onClick={() => {closeMobileMenu(); handleCallClick();}}>
                    +4372990354
                    </Link>
                </li>


            </ul>
            {button && <Button buttonStyle="btn--outline">+4372990354</Button>}

        </div>
    </nav>
    </>
  )
}

export default Navbar;