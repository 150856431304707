import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards() {
  return (
    <div id="section2" className='cards'>
        <h1 style={ {color: 'black', textAlign: 'center'}}>Our Services</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
                
                    <CardItem 
                    
                    src="img-3.jpg"
                    text="Epoxy Flooring"
                    text_details = "Upgrade your space with our premium epoxy flooring—durable, stylish, and perfect for any setting. Contact us today!"
                    label="Adventure"
                    path='/services?type=epoxy-flooring'
                    
                    
                    />

<CardItem 
                    
                    src="img-8.jpg"
                    text="Sidewalk Flooring"
                    text_details = "Upgrade your outdoor spaces with durable and stylish sidewalk flooring. Discover our range of high-quality, slip-resistant options designed for long-lasting performance."
                     
                    label="Adventure"
                    path='/services?type=sidewalk-flooring'
                    
                    />
                       <CardItem 
                    
                    src="img-7.jpg"
                    text="Concrete repairs"
                    text_details = "Restore your surfaces with our expert concrete repair solutions. Durable, high-quality fixes for cracks and damage."

                    label="Adventure"
                    path='/services?type=concrete-repairs'
                    
                    
                    />
                       <CardItem 
                    
                    src="img-6.jpg"
                    text="Brick repairs"
                    text_details = " Revitalize your brickwork with our professional repair services. Quality fixes for cracks and wear."

                    label="Adventure"
                    path='/services?type=brick-repairs'
                    
                    
                    />
                       <CardItem 
                    
                    src="img-5.jpg"
                    text="Paving interlock"
                    text_details = "Enhance your driveways and walkways with our premium paving interlock options. Durable and stylish for any outdoor space."

                    label="Adventure"
                    path='/services?type=paving-interlock'
                    
                    
                    />   <CardItem 
                    
                    src="img-4.jpg"
                    text="Stucco finishes & repairs"
                    text_details = "Update or repair your stucco with our reliable services. Smooth, durable finishes and effective repairs for a refreshed look."

                    label="Adventure"
                    path='/services?type=stucco-finishes-repairs'
                    
                    
                    />
            </div>
        </div>
    </div>
  )
}

export default Cards