// ServicePage.js
import React from 'react';
import './ServicePage.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


function ServicePage() {


  const handleCallClick = () => {
    window.location.href = 'tel:+1234567890'; // Replace with the desired phone number
  };




  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth',  block: 'start' });
    }
  };







  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceType = queryParams.get('type');

  const getServiceContent = (type) => {
    switch (type) {
      case 'epoxy-flooring':
        return {
          text: 'Finding reliable epoxy flooring services can be challenging. In Toronto, we specialize in high-quality epoxy flooring solutions that enhance the durability and appearance of your floors. Trust us for professional installation and maintenance tailored to your needs.',
          texttitle: 'Epoxy flooring Toronto',

          text1: "Transform your home or commercial property’s interior with our top-quality epoxy flooring solutions in Toronto. Our expertly crafted epoxy floors offer a sleek, modern finish while providing durability and ease of maintenance. Enhance your space with the added elegance and functionality that only epoxy flooring can provide. ",

          text2:'At Recon, we specialize in designing and installing custom epoxy flooring tailored to your specific needs. Whether you’re looking to upgrade your home’s garage, revamp a commercial space, or create a durable, high-performance floor for industrial applications, our team of professionals in Toronto is here to assist. ',
          text3:'We are the leading experts in epoxy flooring, known for our exceptional craftsmanship and attention to detail. Our Toronto-based team excels in various epoxy applications, including decorative finishes, high-traffic areas, and industrial-grade flooring solutions. We are committed to delivering high-quality results that not only meet but exceed your expectations. Reach out to us today, and one of our experienced specialists will discuss your epoxy flooring project. Call us at (416) 123-4567, and let’s start transforming your space with stunning, durable epoxy floors!',
          text4:'', 
          image1:'epoxy-flooring1.jpeg',

          image: 'epoxy-flooring.jpg'
        };
      case 'concrete-repairs':
        return {
          text: 'Concrete repairs require expertise and precision. In Toronto, we offer dedicated concrete repair services to address cracks, stains, and structural issues. Our skilled team ensures your concrete surfaces are restored to their original condition with lasting results.',
          texttitle: 'Concrete repairs Toronto',
          text1: "Revitalize your surfaces with our expert concrete repair services, designed to restore both the strength and appearance of your concrete structures. Our repair solutions address issues such as cracks, uneven surfaces, and surface deterioration, ensuring your concrete looks and performs like new.",
          text2:'Our team uses high-quality materials and advanced techniques to repair and reinforce concrete, extending the life of your surfaces and preventing further damage. Whether it’s a residential driveway, commercial floor, or public walkway, our repairs are tailored to match the existing concrete and blend seamlessly with the surrounding area. We prioritize thorough preparation and precise application to ensure a durable and aesthetically pleasing finish. By choosing our concrete repair services, you ensure that your surfaces remain safe, functional, and visually appealing.',
          text3:'',
          text4:'',
          image1:'img-7.jpg',
          image: 'concrete-repairs-image.jpg'
        };
        case 'sidewalk-flooring':
          return {
            text: 'Maintaining and upgrading sidewalk flooring is essential for both safety and aesthetics. In Toronto, we provide expert sidewalk flooring services, including installation and repairs, to ensure your sidewalks are functional, safe, and visually appealing.',
            texttitle: 'Sidewalk flooring Toronto',
            text1: "Transform your outdoor spaces with our high-quality sidewalk flooring solutions, designed to withstand the rigors of foot traffic and various weather conditions while combining durability with aesthetic appeal. Whether you're interested in classic concrete, decorative pavers, or modern permeable options, our range of styles will enhance both the functionality and visual appeal of your pathways.",
            text2:"Our sidewalk flooring is engineered for durability, resisting wear, stains, and environmental impacts to ensure a long-lasting surface. It requires minimal upkeep, making it easy to clean and maintain. Choose from a variety of styles, including traditional concrete, decorative stone, and innovative permeable options, to match your project's design. The flooring is also designed to provide a safe walking surface, even in wet conditions. For those seeking eco-friendly options, we offer permeable designs that facilitate better water drainage and reduce environmental impact.",
            text3:'Elevate your outdoor areas with sidewalk flooring that merges functionality with style, ensuring every step on your paths is both comfortable and visually appealing.',
            text4:'',
            image1:'sidewalk-flooring1.jpg',

            image: 'sidewalk_construction.jpg'
          };
          case 'brick-repairs':
          return {
            text: 'Brickwork requires specialized skills for effective repairs. In Toronto, we offer comprehensive brick repair services to address issues such as cracks, loose bricks, and structural damage. Our experienced team restores the integrity and appearance of your brick surfaces.',
            texttitle: 'Brick repairs Toronto',
            text1: "Enhance the durability and appearance of your brickwork with our comprehensive brick repair services. We specialize in fixing issues such as cracked or damaged bricks, deteriorated mortar joints, and structural concerns, ensuring that your brick structures are restored to their original charm and functionality.",
            text2:'Our repair process involves carefully matching replacement bricks to the existing ones and re-pointing mortar joints to strengthen and stabilize the wall. We use high-quality materials and techniques to ensure that repairs blend seamlessly with the original brickwork, maintaining both the aesthetic and structural integrity of your building. From residential facades to historic buildings, our brick repair services address both cosmetic and structural issues, preserving the beauty and longevity of your brick surfaces.',
            text3:'',
            text4:'',
            image1:'brick-repairs1.jpg',

            image: 'brick-repairs-image.jpg'
          };
          case 'paving-interlock':
          return {
            text: 'Quality paving interlock can enhance the look and functionality of your outdoor spaces. In Toronto, we provide professional paving interlock services, including design and installation, to create durable and attractive pathways, driveways, and patios.',
            texttitle: 'Paving interlock Toronto',
            text1: "Elevate your outdoor spaces with our premium paving interlock solutions, designed to create stylish and durable surfaces for driveways, walkways, patios, and more. Our interlock paving offers a versatile and attractive alternative to traditional concrete or asphalt, providing a customizable and long-lasting finish for any outdoor area.",
            text2:"We use high-quality interlocking pavers available in a variety of shapes, colors, and patterns to suit your design preferences. These pavers are meticulously installed to ensure a secure fit and a visually appealing finish. The interlocking design allows for natural expansion and contraction, reducing the risk of cracks and maintaining a smooth surface over time. Our paving interlock services also include expert preparation of the base, precise installation, and final touches to ensure a flawless result. Enhance the beauty and functionality of your outdoor areas with paving interlock that combines aesthetic appeal with exceptional durability.",
            text3:'',
            text4:'',
            image1:'paving-interlock.png',

            image: 'paving-interlock-image.jpg'
          };
          case 'stucco-finishes-repairs':
          return {
            text: 'Stucco finishes and repairs require precision and expertise. In Toronto, we offer top-notch stucco services to enhance the appearance and durability of your property. From new finishes to repairing existing stucco, our team delivers exceptional results.',
            texttitle: 'Stucco finishes & repairs Toronto',
            text1: "Transform and rejuvenate your building’s exterior with our expert stucco finishes and repair services. Whether you're looking to refresh the look of your property or address issues such as cracks, peeling, or wear, our stucco solutions provide a durable and aesthetically pleasing finish.",
            text2:"We offer a range of stucco finishes, from smooth and modern to textured and traditional, allowing you to choose the style that best complements your property. Our skilled team applies high-quality stucco materials and techniques to ensure a smooth and uniform application. For repairs, we address issues like cracks and damage by meticulously matching the existing stucco and applying a seamless repair that restores both function and appearance. Our stucco finishes and repairs not only enhance the visual appeal of your building but also provide protection against the elements, ensuring a long-lasting and resilient exterior.",
            text3:'',
            text4:'',
            image1:'stucco1.jpg',

            image: 'stucco-finishes-image.jpg'
          };
      default:
        return {
          text: 'Epoxy flooring, sidewalk flooring, concrete, brick repairs, interlock paving, stucco finishes & repairs, renovations and anything else building related. You name it we do it. Servicing residential, commercial and industrial.',
          texttitle: 'Toronto Construction Services',
          text1: "Epoxy flooring, sidewalk flooring, concrete, brick repairs, interlock paving, stucco finishes & repairs, renovations and anything else building related. You name it we do it. Servicing residential, commercial and industrial.",
          text2:'',
          text3:'',
          text4:'',
          image1:'epoxy-flooring1.jpeg',

          image: 'default-image.jpg'
        };
    }
  };

  const { text, texttitle,  text1, text2, text3, text4,  image , image1} = getServiceContent(serviceType);

  return (
    <div>
    <div className="container">
    <div className="content">
      <h1>{texttitle}</h1>
      <p className="content-text">{text}</p>
      <div className="button_container">
        <button className="content_buttons"  onClick={() => scrollToSection('section1')}>REQUEST A QUOTE</button>
        <button className="content_buttons" onClick={() => { handleCallClick();}}> CALL US NOW </button>
      </div>
    </div>
    <div className="diagonal-image" style={{background: `url(${image1}) `, }} >

    </div>
    </div>
    <div className="service-description">
        <div>
        <p className="description_title">{texttitle}</p>
        <p className='muti'>{text1}</p>
        <p className='muti'>{text2}</p>
        <p className='muti'>{text3}</p>
        <p className='muti'>{text4}</p>

</div>
<img src={image}/>
    </div>
</div>


  );
}

export default ServicePage;