//import React from 'react'
import { Button } from './Button'
import './contact.css'
import { Link } from 'react-router-dom';

import React, { useState } from 'react';

import emailjs from 'emailjs-com';

function Contact() {
 

        const [formData, setFormData] = useState({
            name: '',
            phone: '',
            email: '',
            message: ''
        });
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };
    
        const handleSubmit = async (e) => {
            e.preventDefault();
            
            // Example API call, you should replace this with your actual email sending logic
            try {
                const result = await emailjs.send(
                    'service_j9w7nkd',  // Replace with your EmailJS service ID
                    'template_62w2zio', // Replace with your EmailJS template ID
                    formData,           // Form data
                    'xzrz39Nmvte_idaS-'      // Replace with your EmailJS user ID
                );
    
                alert('Email sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                });
            } catch (error) {
                console.error('Error:', error);
                alert('Error sending email.');
            }
        };
    
    


  


    return (
        <div id="section1" className="contact_quote">
            <p>Contact Us</p>
            <p>Request a quote</p>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    name="name" 
                    placeholder="Name*" 
                    value={formData.name}
                    onChange={handleChange}
                    required 
                />
                <input 
                    type="text" 
                    name="phone" 
                    placeholder="Phone No*" 
                    value={formData.phone}
                    onChange={handleChange}
                    required 
                />
                <input 
                    type="email" 
                    name="email" 
                    placeholder="Email Address*" 
                    value={formData.email}
                    onChange={handleChange}
                    required 
                />
                <textarea 
                    name="message" 
                    placeholder="Message*" 
                    value={formData.message}
                    onChange={handleChange}
                    required 
                />
                <button type="submit">SUBMIT</button>
            </form>
        </div>
    );
}

export default Contact;



/*
function Contact() {
    return (
        <div id="#contact" className="contact_quote">
            <p>Contact Us</p>
            <p>Request a quote</p>
            <input type="text" placeholder='Name*'/>
            <input type="text" placeholder='Phone No*'/>
            <input type="text" placeholder='Email Address*'/>
            <textarea placeholder='Message*'></textarea>
            <button>SUBMIT</button>
        </div>
    )
}
export default Contact;

*/